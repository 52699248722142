import React, { useState, useEffect, useContext } from 'react';
import {
  DialogContent, LinearProgress, makeStyles, withStyles
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { dataFetcher, endpoints } from 'Api';
import useSWR from 'swr';
import cn from 'classnames';
import { AppContext } from 'Context';
import { ContentBox, ContentBoxSection } from '../components/ContentBox';
import { DialogFooter } from '../components/Material-reDesign';
import PlaylistInput from '../components/PlaylistInput';
import CategoryInput from '../components/CategoryInput';
import SubCategoryInput from '../components/SubCategoryInput';
import { Button } from '../../button';

import styles from '../index.module.css';

const useStyles = makeStyles({
  colorPrimary: {
    background: '#5aa5ef'
  }
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);
export default function UpdateCategoryPlaylist(
  { uploadedData, moveToNextStep, currentStep, moveToPreviousStep, setUploadedData, videoID, uploadText, commonText, conversionStart }
) {
  const { setAppSnackbar } = useContext(AppContext);
  const [videoCategory, setVideoCategory] = useState(uploadedData?.category_id);
  const [videoCategoryName, setVideoCategoryName] = useState(uploadedData?.category_name);
  const [videoSubCategory, setVideoSubCategory] = useState(uploadedData?.subcategory_id);
  const [videoSubCategoryName, setVideoSubCategoryName] = useState(uploadedData?.subcategory_name);

  const [videoPlaylists, setVideoPlaylists] = useState(uploadedData?.playlist_id || []);
  const [videoPlaylistsNames, setVideoPlaylistsNames] = useState([]);

  const [videoCategoryPlaylistData, setVideoCategoryPlaylistData] = useState(undefined);
  const classes = useStyles();
  const { isValidating: uploadingCategoryPlaylist, mutate: updateCategoryPlaylistContent } = useSWR([
    endpoints.addVideoContent, videoCategoryPlaylistData,
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success }) => {
      if (success) {
        moveToNextStep();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  const updateCategoryPlaylist = () => {
    const formData = new FormData();

    setUploadedData({
      ...uploadedData,
      ...videoCategory !== uploadedData.category_id && { category_id: videoCategory },
      ...videoSubCategory !== uploadedData.subcategory_id && { subcategory_id: videoSubCategory },
      ...videoPlaylists?.length !== uploadedData.playlist_id && { playlist_id: videoPlaylists?.join(',') },
    });

    formData.append('video_id', videoID);
    formData.append('category_id', videoCategory);
    formData.append('subcategory_id', videoSubCategory);
    formData.append('playlist_id', videoPlaylists?.join(','));
    formData.append('status', 0);

    setVideoCategoryPlaylistData(formData);
  };
  useEffect(() => {
    if (videoCategoryPlaylistData && currentStep === 2) { updateCategoryPlaylistContent(); }
  }, [videoCategoryPlaylistData]);
  // useEffect(() => { setVideoSubCategory(undefined); setVideoSubCategoryName(undefined); }, [videoCategory]);

  return (
    <>

      <DialogContent className={styles.content}>
        <ContentBox>
          <ContentBoxSection
            className={styles.detailsBoxSection}
            description={uploadText?.categoriesDescription || 'This section is Required in order to proceed'}
            title={uploadText?.categories || 'Categories'}
          >
            <div className={styles.categoriesContainer}>
              <CategoryInput
                defaultValue={videoCategory}
                defaultName={videoCategoryName}
                description={uploadText?.selectCategoryDesc || 'Select a main category that your video fits into.'}
                label={uploadText?.selectCategory || 'Category'}
                onSelect={(val, name) => { setVideoCategory(val); setVideoCategoryName(name); }}
              />
              <SubCategoryInput
                defaultValue={videoSubCategory}
                defaultName={videoSubCategoryName}
                description={uploadText?.selectSubCategoryDesc || 'Better sort your video into a specific subcategory.'}
                // isDisabled={!videoCategory}
                label={uploadText?.selectSubCategory || 'Sub-Category'}
                onSelect={(val, name) => { setVideoSubCategory(val); setVideoSubCategoryName(name); }}
              />
              <PlaylistInput
                className={styles.playlist}
                defaultValues={videoPlaylists}
                defaultNames={videoPlaylistsNames}
                description={uploadText?.playlistDesc || 'Add your video to one or more playlist. Playlist’s can help your audience view special collections.'}
                label={uploadText?.playlist || 'Playlist'}
                onSelect={(val, name) => {
                  setVideoPlaylists([...val]);
                  setVideoPlaylistsNames(name);
                }}
              />
            </div>
          </ContentBoxSection>
        </ContentBox>
      </DialogContent>
      <DialogFooter twoEnd={conversionStart}>
        <div className={styles.buttonOnTwoEnd}>
          {
            conversionStart ? (
              <div className={styles.leftContainer}>
                <div className={styles.iconContainer}>
                  <CloudUploadOutlined
                    className={styles.videoUploadIcon}
                    fontSize="large"
                  />
                </div>
                <div className={cn({ [styles.progressbar]: true })}>
                  <BorderLinearProgress />
                  <div className={styles.processingText}>
                    Processing in background...
                  </div>
                </div>
              </div>
            ) : null
          }
          <Button
            className={styles.navButton}
            isBlue
            isDisabled={uploadingCategoryPlaylist}
            isOutlined
            onClick={moveToPreviousStep}
          >
            {commonText?.previous || 'Previous'}
          </Button>
          <Button
            className={styles.navButton}
            isBlue
            isDisabled={uploadingCategoryPlaylist
            // || !(videoCategory)}
            || !(videoCategory && videoSubCategory)}
            isOutlined
            onClick={((videoCategory === uploadedData.category_id)
            && (videoSubCategory === uploadedData.subcategory_id))
              ? moveToNextStep
              : updateCategoryPlaylist}
          >
            {uploadingCategoryPlaylist ? (commonText?.updating || 'Updating...') : (commonText?.next || 'Next')}
          </Button>
        </div>
      </DialogFooter>

    </>
  );
}
