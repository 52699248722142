import React, { useState } from 'react';
import cn from 'classnames';
import pt from 'prop-types';
import { ReactComponent as VisibleIcon } from 'Assets/eye.svg';
import { ReactComponent as HiddenIcon } from 'Assets/eye.slash.svg';
import styles from './index.module.css';

const PasswordInput = ({ className, isDisabled, ...props }) => {
  const [isPswdVisible, setPswdVisibility] = useState(false);

  return (
    <>
      <input
        className={cn({ [styles.inputField]: true, [className]: className })}
        disabled={isDisabled}
        id="password-input"
        minLength="8"
        type={isPswdVisible ? 'text' : 'password'}
        {...props}
      />
      <label className={styles.passwordVisibilityToggle} htmlFor="password-input">
        {isPswdVisible
          ? (
            <HiddenIcon
              className={styles.passwordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )
          : (
            <VisibleIcon
              className={styles.passwordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )}
      </label>
    </>
  );
};

const EmailInput = ({ className, isDisabled, isLoading, ...props }) => (
  <>
    <input
      className={cn({ [styles.inputField]: true, [className]: className })}
      disabled={isDisabled}
      type="email"
      {...props}
    />
    <div className={cn({ [styles.spinnerContainer]: true, [styles.loading]: isLoading })}>
      <div style={{ borderColor: '#ffffff44' }} className={styles.spinner} />
      <div style={{ borderColor: '#5aa5ef' }} className={cn({ [styles.spinner]: true, [styles.animatedSpinner]: true })} />
    </div>
  </>
);

const CodeInput = ({ className, isDisabled, isLoading, ...props }) => (
  <input className={cn({ [styles.inputField]: true, [className]: className })} disabled={isDisabled} type="text" {...props} />
);

export const Input = ({ containerClassName, isLoading, label, labelClassName, message, type, noLabel, ...props }) => (
  <>
    {!noLabel && label ? (
      <label className={cn({ [styles.inputLabel]: true, [labelClassName]: labelClassName })}>{label}</label>
    ) : null }
    <div
      className={cn({
        [styles.inputFieldContainer]: true,
        [styles.loading]: isLoading,
        [styles.passwordInput]: type === 'password',
        [containerClassName]: containerClassName,
      })}
    >
      {type === 'email'
        ? <EmailInput isLoading={isLoading} {...props} />
        : type === 'code'
          ? <CodeInput {...props} />
          : <PasswordInput {...props} />}
    </div>
    {message && <span className={styles.inputMessage}>{message}</span>}
  </>
);

Input.defaultProps = {
  className: undefined,
  isLoading: false,
  message: undefined,
  placeholder: undefined,
};

Input.propTypes = {
  className: pt.string,
  isLoading: pt.bool,
  label: pt.string,
  message: pt.string,
  placeholder: pt.string,
  type: pt.string.isRequired,
};
