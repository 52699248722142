/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import { Close, ExpandLess, ExpandMore, Edit, Check, ErrorOutline } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { AppContext } from 'Context';
import styles from './index.module.css';

const ProgressBox = () => {
  const [isMinimize, setSize] = useState(false);
  const [openEditVideoModal, setEditVideoModal] = useState(false);
  const { setProgressBox, files, uploadingFiles, isUploadSuccess, setFiles } = useContext(AppContext);
  const [selectedId, selectId] = useState(undefined);

  const progressBoxCloseHandler = () => {
    setProgressBox(false);
    setFiles(undefined);
  };

  const hideModal = () => {
    setEditVideoModal(false);
  };
  const editHandler = (uniqueIdentifier) => {
    setEditVideoModal(true);
    selectId(uniqueIdentifier);
  };

  return (
    <>
      <div className={styles.progressBoxWrap}>
        <div className={styles.header}>
          <div className={styles.headerText}>Uploads</div>
          <div className={styles.headerAction}>
            {
              isMinimize ? <ExpandLess onClick={() => setSize(!isMinimize)} className={styles.icon} /> : <ExpandMore onClick={() => setSize(!isMinimize)} className={styles.icon} />
            }
            {
              uploadingFiles ? (
                <div className={styles.icon} />
              ) : (
                <Close className={styles.icon} onClick={() => progressBoxCloseHandler()} />
              )
            }
          </div>
        </div>
        {
          !isMinimize ? (
            <div id="uplist">
              {
                Object.keys(files)?.map((video) => (
                  <div className={styles.fileItem}>
                    <Edit className={styles.editIcon} onClick={() => { editHandler(files[video]?.fileId); }} />
                    <div className={styles.fileName}>{files[video]?.name}</div>
                    {
                        uploadingFiles ? (
                          <CircularProgress size={20} />
                        ) : isUploadSuccess ? (
                          <Check className={styles.statusIcon} />
                        ) : (
                          <ErrorOutline className={styles.errorIcon} />
                        )
                      }
                  </div>
                ))
              }
            </div>
          ) : null
        }
      </div>
    </>
  );
};
export default ProgressBox;
