import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  LinearProgress as MuiLinearProgress,
  withStyles,
  Fade,
} from '@material-ui/core';

export const Dialog = withStyles({ paper: { boxShadow: 'none', backgroundColor: 'transparent' } })(MuiDialog);
export const DialogTitle = withStyles({ root: { backgroundColor: '#fff' } })(MuiDialogTitle);
export const DialogContent = withStyles({ root: { backgroundColor: '#fff', borderRadius: '0px' } })(MuiDialogContent);
export const DialogActions = withStyles({ root: { backgroundColor: '#fff' } })(MuiDialogActions);
export const LinearProgress = withStyles({ root: { height: '4px' } })(MuiLinearProgress);
