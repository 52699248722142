import NoContentIcon from 'Assets/category.png';
import styles from './index.module.css';

export const NoContent = ({ contentType, fileHandler, description }) => (
  <div className={styles.container}>
    <div className={styles.noContentWrap}>
      <img className={styles.noConetntSvg} src={NoContentIcon} />
      <div className={styles.noContentText}>
        {`No ${contentType}`}
      </div>
      <div className={styles.noContentSubText}>
        {description}
      </div>
      <label htmlFor="file-multiple" className={styles.addContentButton}>
        +
      </label>
      <input
        type="file"
        id="file-multiple"
        accept=".mp4,.mov,.m4v,.png,.jpg,.jpeg,.txt,.pdf,.doc,.docx"
        className={styles.fileUploadButton}
        onInput={(e) => {
          fileHandler(e);
        }}
        multiple
      />
    </div>
  </div>
);
