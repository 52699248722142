import React from 'react';
import { render } from 'react-dom';
import { SWRConfig } from 'swr';
import { dataFetcher } from 'Api';
import { GoogleOAuthProvider, useGoogleLogin, googleLogout } from '@react-oauth/google';
import AOS from 'aos';
// import reportWebVitals from './reportWebVitals';
import { App } from './app';
// import './index.css';
import 'aos/dist/aos.css';

AOS.init();
render((
  <SWRConfig
    value={{
      fetcher: dataFetcher,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      // shouldRetryOnError: false,
    }}
  >
    <App />
  </SWRConfig>
), document.getElementById('root'));

// reportWebVitals();
