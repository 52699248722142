import cn from 'classnames';
import Shimmer from 'react-shimmer-effect';
import styles from './index.module.css';

export const ProjectorShimmer = () => ((
  <div className={styles.shimmerWrapper}>
    <Shimmer>
      <div className={styles.line} />
    </Shimmer>
    <div className={styles.imageWrap}>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
    </div>
    <Shimmer>
      <div className={styles.line} />
    </Shimmer>
    <div className={styles.imageWrap}>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
      <Shimmer>
        <div className={styles.square} />
      </Shimmer>
    </div>
  </div>
));
