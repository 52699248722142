import { ReactComponent as Filter } from 'Assets/filter.svg';
import styles from './index.module.css';

export const SubHeading = ({ contentType, noOfContents }) => (
  <div className={styles.subHeadingContainer}>
    <div className={styles.subHeading}>
      {noOfContents > 0
        ? ` ${noOfContents} ${contentType}`
        : `No ${contentType} `}
      {}
    </div>
    <div className={styles.filterWrap}>
      <span className={styles.filterText}>Filter By  </span>
      <Filter />

    </div>
  </div>
);
