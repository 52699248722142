/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import React, { useContext, useState, useEffect } from 'react';
import { Page, Text, ProjectorShimmer, NoContent, SubHeading } from 'Components';
import { TextField } from '@material-ui/core';
import useSWR from 'swr';
import { AppContext } from 'Context';
import AlterImage from 'Assets/alter-image.png';
import { dataFetcher, endpoints } from 'Api';
import styles from './index.module.css';

export const Video = () => {
  const { files, setFileUploadData, setAppSnackbar, langText, fileHandler } = useContext(AppContext);
  const [videos, setVideos] = useState(undefined);
  const commonText = langText?.common;
  const [searchKey, setSearchKey] = useState(undefined);

  const { isValidating: gettingVideos, mutate: getVideos } = useSWR([endpoints.files, 1, searchKey], {
    fetcher: (url, type, searchKey) => dataFetcher(url, { type, offset: 0, limit: 100, search: searchKey }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        setVideos(data);
      } else {
        setAppSnackbar({ message: message || commonText?.errorMessage, isVisible: true, type: 'error' });
        setVideos(undefined);
      }
    },
    onError: (error) => {
      const responseMsg = error?.response?.data?.message;
      setAppSnackbar({ message: responseMsg || commonText?.errorMessage, isVisible: true, type: 'error' });
      setVideos(undefined);
    },
  });

  useEffect(() => {
    getVideos();
  }, [searchKey]);

  const searchHandler = (event) => {
    const lowerCase = event.target.value.toLowerCase();
    setSearchKey(lowerCase);
  };

  return (
    <Page contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <div className={styles.dashboard}>
        {/* <Text.PageTitle>Videos</Text.PageTitle>
        <hr className={styles.pageDivider} /> */}
        {
          videos ? (
            <>
              <SubHeading contentType="Videos in total" noOfContents={videos?.length || 0} />
              <div className={styles.searchField}>
                <TextField
                  id="outlined-basic"
                  onChange={searchHandler}
                  variant="outlined"
                  label="Search by Name"
                  size="small"
                />
              </div>
            </>
          ) : null
        }
        {
          gettingVideos ? (
            <ProjectorShimmer />
          ) : videos?.length ? (
            <>
              <div className={styles.contentWrapper}>
                {
                  videos?.map(({ thumbnail_url, file_name }) => (
                    <div className={styles.imgWrap} key={thumbnail_url}>
                      <img
                        className={styles.imageStyle}
                        src={thumbnail_url}
                        onError={(e) => {
                          e.target.src = AlterImage;
                        }}
                      />
                      <div className={styles.fileName}>{file_name}</div>
                    </div>
                  ))
                }
              </div>
            </>
          ) : (
            <NoContent contentType="Videos" fileHandler={fileHandler} description="Once you’ve set up a property, you can preview, edit or delete it here." />
          )
        }
      </div>
    </Page>
  );
};
