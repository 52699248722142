import React from 'react';
import pt from 'prop-types';
import { Snackbar as MuiSnackbar, SnackbarContent as MuiSnackbarContent, withStyles } from '@material-ui/core';

const SnackbarTypes = { default: 'default', error: 'error', success: 'success', banner: 'banner' };
const DefaultSnackbarContent = withStyles(() => ({
  root: {
    color: '#fff',
    fontWeight: 'bold',
    justifyContent: 'center',
  },
}))(MuiSnackbarContent);
const SnackbarContent = {
  [SnackbarTypes.default]: withStyles(() => ({}))(DefaultSnackbarContent),
  [SnackbarTypes.error]: withStyles(() => ({ root: { backgroundColor: '#ff0000' } }))(DefaultSnackbarContent),
  [SnackbarTypes.success]: withStyles(() => ({ root: { backgroundColor: 'green' } }))(DefaultSnackbarContent),
  [SnackbarTypes.banner]: withStyles(() => ({ root: { backgroundColor: 'blue', width: '100% !important' } }))(DefaultSnackbarContent),

};

export const Snackbar = ({ autoHideDuration, isVisible, message, onClose, type }) => {
  const CurrentSnackbarContent = SnackbarContent[type];
  const camelCase = (str) => str.replace(/[^A-Za-z0-9]/g, ' ').replace(/^\w|[A-Z]|\b\w|\s+/g, (match, index) => {
    if (+match === 0 || match === '-' || match === '.') {
      return ' '; // or if (/\s+/.test(match)) for white spaces
    }
    return match.toUpperCase();
  });
  return (
    <MuiSnackbar autoHideDuration={autoHideDuration} onClose={onClose} open={isVisible}>
      <CurrentSnackbarContent message={camelCase(message)} />
    </MuiSnackbar>
  );
};

Snackbar.defaultProps = {
  autoHideDuration: 4500,
  type: SnackbarTypes.default,
};

Snackbar.propTypes = {
  autoHideDuration: pt.number,
  isVisible: pt.bool.isRequired,
  message: pt.string.isRequired,
  onClose: pt.func.isRequired,
  type: pt.oneOf(Object.values(SnackbarTypes)),
};
