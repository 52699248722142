/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from 'Context';
import { Logo } from 'Components';
import { Pathname } from 'Routes';
import AlterImage from 'Assets/alter-image.png';
import styles from '../../auth.module.css';

export const AuthPage = ({ children, smallIcon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { langText } = useContext(AppContext);
  const commonText = langText?.common;
  return (
    <div className={styles.authPage}>
      <div className={styles.authPageContent}>
        <Link to={Pathname.authentication.signIn}>
          <Logo className={styles.logo} isLarge={!smallIcon} isLight />
        </Link>
        {children}
      </div>
    </div>
  );
};
