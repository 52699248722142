import NoContentIcon from 'Assets/category.png';
import styles from './index.module.css';

export const NoGoogleContent = ({ contentType, fileHandler, description }) => (
  <div className={styles.container}>
    <div className={styles.noContentWrap}>
      <img className={styles.noConetntSvg} src={NoContentIcon} />
      <div className={styles.noContentText}>
        {`No ${contentType}`}
      </div>
      <div className={styles.noContentSubText}>
        {description}
      </div>
    </div>
  </div>
);
