/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import React, { useContext, useState, useEffect } from 'react';
import { Page, Text, ProjectorShimmer, NoContent, SubHeading } from 'Components';
import { TextField } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { AppContext } from 'Context';
import AlterImage from 'Assets/alter-image.png';
import DocumentIcon from 'Assets/document-icon.jpg';
import { dataFetcher, endpoints } from 'Api';
import styles from './index.module.css';

export const Document = () => {
  const { files, setFileUploadData, setAppSnackbar, langText, fileHandler } = useContext(AppContext);
  const [documents, setDocuments] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const commonText = langText?.common;

  const { isValidating: gettingDocuments, mutate: getDocuments } = useSWR([endpoints.files, 3, searchKey, setAppSnackbar], {
    fetcher: (url, type, searchKey) => dataFetcher(url, { type, offset: 0, limit: 100, search: searchKey }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        setDocuments(data);
      } else {
        setAppSnackbar({ message: message || commonText?.errorMessage, isVisible: true, type: 'error' });
      }
    },
    onError: (error) => {
      const responseMsg = error?.response?.data?.message;
      setAppSnackbar({ message: responseMsg || commonText?.errorMessage, isVisible: true, type: 'error' });
    },
  });

  useEffect(() => {
    getDocuments();
  }, [searchKey]);

  const searchHandler = (event) => {
    const lowerCase = event.target.value.toLowerCase();
    setSearchKey(lowerCase);
  };

  return (
    <Page contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <div className={styles.dashboard}>
        {
          documents ? (
            <>
              <SubHeading contentType="Documents" noOfContents={documents?.length || 0} />
              <div className={styles.searchField}>
                <TextField
                  id="outlined-basic"
                  onChange={searchHandler}
                  variant="outlined"
                  label="Search by Name"
                  size="small"
                />
              </div>
            </>
          ) : null
        }
        {
          gettingDocuments ? (
            <ProjectorShimmer />
          ) : documents?.length ? (
            <>
              <div className={styles.contentWrapper}>
                {
                  documents?.map(({ file_url, file_name }) => (
                    <div className={styles.imgWrap} key={file_url}>
                      <img
                        className={styles.imageStyle}
                        src={DocumentIcon}
                        onError={(e) => {
                          e.target.src = AlterImage;
                        }}
                        onClick={() => window.open(file_url, '_blank')}
                      />
                      <div className={styles.fileName}>{file_name}</div>
                    </div>
                  ))
                }
              </div>
            </>
          ) : (
            <NoContent contentType="Documents" fileHandler={fileHandler} description="Once you’ve set up a property, you can preview, edit or delete it here." />
          )
        }
      </div>
    </Page>
  );
};
