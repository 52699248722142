import React from 'react';
import cn from 'classnames';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Fade,
  Tooltip,
  withStyles,
  Zoom,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
import { Button } from '../button';
import { Text } from '../text';
import styles from './index.module.css';

const DialogActions = withStyles({ root: { padding: 'unset' } })(MuiDialogActions);
const DialogContent = withStyles({ root: { padding: 'unset' } })(MuiDialogContent);

const ModalContent = ({
  cancelButton,
  cancelButtonClassName,
  cancelButtonProps,
  content,
  continueButton,
  continueButtonClassName,
  continueButtonProps,
  isVisible,
  noFooter,
  onCancelButtonClick,
  onContinueButtonClick,
  onHide,
  title,
  infoButton,
  addContentModal,
  selectedCount
}) => ((
  <Fade in={isVisible} timeout={isVisible ? 400 : 350}>
    <div className={styles.body}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <BackIcon className={styles.backButton} onClick={onHide} />
          <Text.PageTitle className={styles.headerText}>
            {title}
            {' '}
            {infoButton ? <Tooltip title="Info"><InfoIcon size="large" /></Tooltip> : null}
          </Text.PageTitle>
          <CloseIcon className={styles.closeButton} onClick={onHide} />
        </div>
      </div>

      <DialogContent className={styles.content}>{content}</DialogContent>

      {!noFooter && (
        <DialogActions className={styles.footer}>
          <div className={cn({ [styles.footerContent]: true, [styles.footerContentForcontent]: addContentModal })}>
            {
              addContentModal ? <div className={styles.selectedCount}>{`${selectedCount} of 5 selected`}</div> : null
            }
            <div>
              {cancelButton && (
                <Button
                  className={cn({ [styles.navButton]: true, [cancelButtonClassName]: cancelButtonClassName })}
                  isBlue
                  isOutlined
                  onClick={onCancelButtonClick}
                  {...cancelButtonProps}
                >
                  {cancelButton}
                </Button>
              )}
              {continueButton && (
                <Button
                  className={cn({ [styles.navButton]: true, [continueButtonClassName]: continueButtonClassName })}
                  isBlue
                  isOutlined
                  onClick={onContinueButtonClick}
                  {...continueButtonProps}
                >
                  {continueButton}
                </Button>
              )}
            </div>
          </div>
        </DialogActions>
      )}
    </div>
  </Fade>
));

export const Modal = ({
  cancelButton,
  cancelButtonClassName,
  cancelButtonProps,
  children,
  continueButton,
  continueButtonClassName,
  continueButtonProps,
  disableBackdropClick,
  isVisible,
  noFooter,
  onCancelButtonClick,
  onContinueButtonClick,
  onHide,
  title,
  infoButton,
  addContentModal,
  selectedCount
}) => ((
  <Dialog
    disableBackdropClick={disableBackdropClick}
    onClose={onHide}
    open={isVisible}
    PaperComponent={ModalContent}
    PaperProps={{
      cancelButton,
      cancelButtonClassName,
      cancelButtonProps,
      content: children,
      continueButton,
      continueButtonClassName,
      continueButtonProps,
      isVisible,
      noFooter,
      onCancelButtonClick,
      onContinueButtonClick,
      onHide,
      title,
      infoButton,
      addContentModal,
      selectedCount
    }}
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: isVisible ? 400 : 350 }}
  />
));
