import React, { useContext, useRef, useState, useEffect } from 'react';
import AXIOS from 'axios';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import Lottie from 'react-lottie';
import {
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  RadioGroup,
  makeStyles,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, AxiosCancelToken, baseUrl } from 'Api';
import { CloudUploadOutlined } from '@material-ui/icons';

import addFileImage from 'Assets/add-file@2x.png';
import noPhotoAvailable from 'Assets/no-image-available.png';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
import { GroupSelector } from '../group-selector';
import { Button } from '../button';
import { Text } from '../text';
import ChooseWhatUpload from './components/ChooseWhatUpload';
import VideoSelect from './components/SelectVideo';
import PhotoSelect from './components/SelectPhoto';
import { Radio, DialogFooter } from './components/Material-reDesign';
import ProgressTracker from './components/ProgressTracker';
import { ContentBox, ContentBoxSection } from './components/ContentBox';
import VideoPreview from './components/VideoPreview';
import PhotoPreview from './components/PhotoPreview';
import InputField from './components/InputField';
import ChooseAlbum from './components/ChooseAlbum';
import AlbumDetails from './components/AlbumDetails';
import AlbumSummary from './components/AlbumSummary';
import LoaderContent from './model-dialog-content/LoaderContent';
import styles from './index.module.css';
import './loader.css';
import UpdateCategoryPlaylist from './model-dialog-content/UpdateCategoryPlaylist';
import VideoDetails from './components/VideoDetails';
import VideoSummary from './components/VideoSummary';

const defaultVideoDetails = {
  name: undefined,
  duration: undefined,
  size: undefined,
};

const useStyles = makeStyles({
  colorPrimary: {
    background: '#5aa5ef'
  }
});

const Modal = ({ isVisible, onHide, onClose }) => {
  const { setAppSnackbar, subscriptionDetails, setUserDetails, userDetails, langText, setPageLoad, setSingleUpload, isSinglUepload, startingFlowEvent, startFlowEvent, setModalVisibility } = useContext(AppContext);
  const navigate = useNavigate();
  let domain = window.location.href;
  const uploadText = langText?.upload;
  const commonText = langText?.common;
  const visibilityText = langText?.visibility;
  const videoVisibilityOptions = [
    { name: visibilityText?.publicDescription || 'Anyone can view on My Projector account', value: '2' },
    { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
    { name: visibilityText?.groupDescription || 'Choose a group to share with', value: '3' },
  ];
  const defaultVideoVisibility = videoVisibilityOptions[0].value;
  const fileInputRef = useRef(null);

  domain = domain?.replace(/http[s]?:\/\//, '');
  domain = domain?.split('/')[0];

  const [currentStep, setCurrentStep] = useState(undefined);

  const [defaultVideoThumbnail, setDefaultVideoThumbnail] = useState(noPhotoAvailable);
  const [thumbnailType, setThumbnailType] = useState('normal');

  const [videoDescription, setVideoDescription] = useState('');
  const [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [videoFile, setVideoFile] = useState(undefined);

  const [videoGroup, setVideoGroup] = useState([]);
  const [videoID, setVideoID] = useState(undefined);

  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [customVideoThumbnails, setCustomVideoThumbnails] = useState([]);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoVisibility, setVideoVisibility] = useState(defaultVideoVisibility);
  const [videoIsPublished, setVideoPublishStatus] = useState(false);
  const [videoIsUploaded, setVideoUploadStatus] = useState(false);

  const [videoUploadData, setVideoUploadData] = useState(undefined);
  // const [videoCategoryPlaylistData, setVideoCategoryPlaylistData] = useState(undefined);
  const [videoVisibilityData, setVideoVisibilityData] = useState(undefined);
  const [runLoader, setrunLoader] = useState(false);
  const [uploadedData, setUploadedData] = useState({
    category_id: undefined,
    description: undefined,
    group_id: undefined,
    playlist_id: undefined,
    publish_date: undefined,
    status: undefined,
    subcategory_id: undefined,
    thumbnails: undefined,
    title: undefined,
    token: undefined,
    video_file: undefined,
    video_id: undefined,
    visibility: undefined,
  });
  const [videoUploadingCompleted, setVideoUploadingCompleted] = useState(false);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);

  const [source, setSource] = useState(undefined);
  const [uploadType, setUpdateType] = useState(undefined);
  const [photoFile, setPhotoFile] = useState([]);
  const [chooseAlbumStep, setChooseAlbumStep] = useState(0);
  const [albumTitle, setAlbumTitle] = useState('');
  const [albumDescription, setAlbumDescription] = useState('');
  const [albumThumbnails, setAlbumThumbnails] = useState([]);
  const [customAlbumThumbnails, setCustomAlbumThumbnails] = useState([]);
  const [defaultAlbumThumbnail, setDefaultAlbumThumbnail] = useState(undefined);
  const [albumUploadData, setAlbumUploadData] = useState(undefined);
  const [albumId, setAlbumId] = useState(undefined);
  const [albumVisibility, setAlbumVisibility] = useState(defaultVideoVisibility);
  const [albumGroup, setAlbumGroup] = useState([]);
  const [multiplePhoto, setMultiplePhoto] = useState(undefined);
  const [albumList, setAlbumList] = useState([]);
  const [chooseExistingAlbum, setExistingAlbum] = useState(undefined);
  const [isAnExistingAlbum, updateAnExistingAlbum] = useState(false);
  const [albumSize, setAlbumSize] = useState(undefined);
  const [albumPhotoCount, setAlbumPhotoCount] = useState(undefined);
  const [albumPublished, setAlbumPublishStatus] = useState(false);
  const [contentType, setContentType] = useState(undefined);
  const [albumStatus, setAlbumStatus] = useState(undefined);
  const [conversionStatus, setConversionStatus] = useState(0);
  const [conversionStart, setConversionStart] = useState(false);
  const [thumbnailGenerationStatus, setThumbnailGenerationStatus] = useState(false);
  const [isStorageFull, setStorageFull] = useState(false);
  const contentDashboard = langText?.contentDashboard;
  const [videoCount, setVideoCount] = useState(undefined);
  let flow;
  const classes = useStyles();

  const handleClose = () => {
    startingFlowEvent(false);
    setModalVisibility(false);
    setSource(AxiosCancelToken.source());
    // alert('cancelled');
    if (isSinglUepload) {
      setSingleUpload(undefined);
    }
    onHide();
  };
  useEffect(() => {
    source?.cancel('Landing Component got unmounted');
  }, [source]);
  const moveToPreviousStep = () => {
    setCurrentStep(-1);
    setTimeout(() => { setCurrentStep(currentStep - 1); }, 10);
  };
  const moveToNextStep = () => {
    if ((currentStep + 1) <= 3) {
      setCurrentStep(-1);
      setTimeout(() => { setCurrentStep(currentStep + 1); }, 10);
    }
  };

  const getToken = () => localStorage.getItem('token');
  const axios = AXIOS.create({ baseURL: baseUrl.URL, method: 'POST' });
  axios.interceptors.request.use((req) => {
    if (req?.data instanceof FormData) {
      const formData = req?.data;

      if (formData.has('token')) { formData.delete('token'); }

      formData.append('token', getToken());

      return ({ ...req, data: formData });
    }

    return ({ ...req, data: { ...req.data, token: getToken() } });
  });

  const dataFetcher2 = (url, data, cancelToken) => axios({
    url,
    ...data && { data },
    onUploadProgress: (ProgressEvent) => {
      const { loaded, total } = ProgressEvent;
      const percentage = Math.floor((loaded * 100) / total);
      if (percentage < 98) {
        setVideoUploadPercentage(percentage);
      } else {
        setVideoUploadPercentage(98);
      }
    },
    ...cancelToken && { cancelToken },

  }).then((res) => res.data);

  const { isValidating: gettingThumbnail, mutate: getThumbnail } = useSWR([
    endpoints.getThumbnails, videoID,
  ], {
    fetcher: (url, content_id) => dataFetcher(url, { content_id }),
    onSuccess: ({ success, message, videos }) => {
      if (success && videos.status) {
        setThumbnailGenerationStatus(false);
        setVideoThumbnails(videos.thumbnails);
        setDefaultVideoThumbnail(videos.thumbnails[0]);
      } else {
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: gettingConvertionStatus, mutate: getConversionStatus } = useSWR([
    endpoints.getVideoStatus, videoID,
  ], {
    fetcher: (url, video_id) => dataFetcher(url, { video_id }),
    onSuccess: ({ success, message, videos }) => {
      if (success) {
        setConversionStatus(videos[0].progress);
        if (videos[0].progress === 100) {
          setTimeout(() => { setConversionStart(false); }, 1000);
        }
      } else {
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  const { isValidating: uploadingVideo, mutate: uploadVideoContent } = useSWR([
    endpoints.addVideoContent, videoUploadData, source?.token], {
    fetcher: (url, formData, uniqueCancelToken) => dataFetcher2(url, formData, uniqueCancelToken),
    onSuccess: ({ success, video_id, suggestedThumbnails, message, has_conversion }) => {
      if (success) {
        moveToNextStep();
        if (!videoIsUploaded) {
          setVideoThumbnails(suggestedThumbnails);
          setDefaultVideoThumbnail(suggestedThumbnails[0]);
          setVideoID(video_id);
          setVideoUploadStatus(true);
        }
        // setThumbnailGenerationStatus(true);
        if (has_conversion) {
          setConversionStart(true);
        }
        // getThumbnail();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        handleClose();
        setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);

      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  useEffect(() => {
    let interval;
    if (conversionStart) {
      interval = setInterval(() => {
        getConversionStatus();
      }, 5000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [conversionStart]);

  useEffect(() => {
    let interval;
    if (thumbnailGenerationStatus) {
      interval = setInterval(() => {
        getThumbnail();
      }, 5000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [thumbnailGenerationStatus]);

  const { isValidating: uploadingVisibility, mutate: updateVisibilityContent } = useSWR([
    endpoints.addVideoContent, videoVisibilityData,
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, message }) => {
      if (success) {
        setUserDetails({
          ...userDetails,
          totalVideoUploaded: userDetails.totalVideoUploaded > 0
            ? userDetails.totalVideoUploaded + 1 : 1,
          totalContentUploaded: userDetails.totalContentUploaded > 0
            ? userDetails.totalContentUploaded + 1 : 1
        });
        setVideoPublishStatus(true);
        if (conversionStart) {
          handleClose();
          setTimeout(() => { navigate(Pathname.processingInBackGround); }, 500);
        } else {
          setTimeout(() => { handleClose(); setPageLoad(true); sessionStorage.setItem('tabTitle', 0); navigate(Pathname.yourVideos); }, 3000);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: updatingAlbum, mutate: addEditAlbum } = useSWR([
    endpoints.addEditAlbum, albumTitle, albumDescription, source?.token], {
    fetcher: (url, title, description, uniqueCancelToken) => dataFetcher(url,
      { title: albumTitle, description: albumDescription }, uniqueCancelToken),
    onSuccess: ({ success, album_id, message }) => {
      if (success) {
        setAlbumId(album_id);
        setChooseAlbumStep(4);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
    },
  });

  const { isValidating: addingPhotos, mutate: addPhotoContent } = useSWR([
    endpoints.addPhotoContent, albumUploadData], {
    fetcher: (url, formData) => dataFetcher2(url, formData),
    onSuccess: ({ success, total_count, has_subsription, message }) => {
      if (success) {
        if (has_subsription) {
          if (total_count) {
            setUserDetails({
              ...userDetails,
              totalContentUploaded: userDetails.totalContentUploaded > 0
                ? userDetails.totalContentUploaded + 1 : 1
            });
          }
          window.sessionStorage.setItem('tabTitle', 1);
          setTimeout(() => { handleClose(); setPageLoad(true); navigate(Pathname.yourVideos); }, 4000);
        } else {
          const albID = isAnExistingAlbum ? chooseExistingAlbum : albumId;
          setTimeout(() => { handleClose(); navigate(Pathname.purchasePlan('album', albID)); }, 4000);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 200);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        handleClose();
        setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 100);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  const { isValidating: gettingAlbumList, mutate: getAlbumsList } = useSWR(endpoints.getAlbumList, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setAlbumList(data);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your photo albums',
        });
      }
    },
    onError: () => {
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: 'Oops! Something went wrong while fetching your photo albums'
      });
    },
  });

  const uploadPhotoToExistingAlbum = () => {
    const formData = new FormData();
    formData.append('album_id', chooseExistingAlbum);
    photoFile.forEach((photo) => {
      formData.append('photo_file[]', photo.file);
    });
    setAlbumUploadData(formData);
  };

  useEffect(() => {
    getAlbumsList();
  }, []);
  const getBase64FromFile = (inputFile) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
      fileReader.onerror = () => {
        fileReader.abort();
        resolve('');
      };

      fileReader.onload = () => {
        resolve(fileReader.result.replace(/^data:.+;base64,/, ''));
      };
      fileReader.readAsDataURL(inputFile);
    });
  };

  const getBase64VideoThumbnails = async () => {
    let base64VideoThumbnails = '';

    return new Promise((resolve) => {
      videoThumbnails?.forEach(async (videoThumbnail, idx) => {
        const base64Thumbnail = await getBase64FromFile(videoThumbnail);

        base64VideoThumbnails = `${base64VideoThumbnails}${base64Thumbnail}`;

        if ((idx + 1) < videoThumbnails?.length) { base64VideoThumbnails = `${base64VideoThumbnails}, }`; }

        if ((idx + 1) === videoThumbnails?.length) { resolve(base64VideoThumbnails); }
      });
    });
  };

  const uplodingVideo = async () => {
    const formData = new FormData();
    formData.append('status', 0);
    formData.append('video_file', videoFile);
    setVideoUploadData(formData);
    if (currentStep === 0) {
      setrunLoader(true);
    }
  };
  const uploadVideo = async () => {
    const formData = new FormData();

    if (!videoIsUploaded) {
      setUploadedData({
        ...uploadedData,
        description: videoDescription,
        title: videoTitle,
        video_file: videoFile,
        thumbnails: videoThumbnails,
      });

      if (videoDescription) { formData.append('description', videoDescription); }
      if (videoTitle) { formData.append('title', videoTitle); }
      // if (videoThumbnails) { formData.append('thumbnails', await getBase64VideoThumbnails()); }
      if (videoThumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      formData.append('status', 0);
      formData.append('video_id', videoID);
      // formData.append('video_file', videoFile);
    } else {
      setUploadedData({
        ...uploadedData,
        ...videoThumbnails !== uploadedData.thumbnails && { thumbnails: videoThumbnails },
        ...videoDescription !== uploadedData.description && { description: videoDescription },
        ...videoTitle !== uploadedData.title && { title: videoTitle },
      });
      formData.append('video_id', videoID);
      // formData.append('video_id', videoID);

      if (videoThumbnails !== uploadedData.thumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      if (videoDescription !== uploadedData.description) {
        formData.append('description', videoDescription);
      }
      if (videoTitle !== uploadedData.title) {
        formData.append('title', videoTitle);
      }
    }

    setVideoUploadData(formData);
  };

  const updateVisibility = () => {
    const formData = new FormData();

    setUploadedData({
      ...uploadedData,
      ...videoVisibility !== uploadedData.visibility && { visibility: videoVisibility },
      ...(videoVisibility === '3') && { group_id: videoGroup },
    });

    formData.append('video_id', videoID);
    formData.append('visibility', videoVisibility);
    formData.append('status', 1);
    if (videoVisibility === '3') {
      formData.append('group_id', videoGroup);
    }
    setVideoVisibilityData(formData);
  };

  useEffect(() => {
    if (videoUploadData && currentStep < 2) { uploadVideoContent(); }
  }, [videoUploadData]);

  const addOrEditAlbum = () => {
    addEditAlbum();
  };

  const convertBase64 = (file) => new Promise((resolve, rej) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      rej(error);
    };
  });

  const publishAlbum = async () => {
    const formData = new FormData();
    formData.append('album_id', albumId);
    formData.append('visibility', albumVisibility);
    if (albumVisibility === '3') {
      formData.append('group_id', albumGroup);
    }
    photoFile.forEach((photo) => {
      formData.append('photo_file[]', photo.file);
    });
    const base64 = await convertBase64(defaultAlbumThumbnail.file);
    formData.append('icon', base64);
    setAlbumUploadData(formData);
  };

  useEffect(() => {
    if (albumUploadData) {
      if (subscriptionDetails?.has_activePlan) {
        setAlbumStatus('Album Published');
      } else {
        setAlbumStatus('Photo Uploaded');
      }
      setContentType('album');
      setAlbumPublishStatus(true);
      addPhotoContent();
    }
  }, [albumUploadData]);
  useEffect(() => {
    if (!videoUploadData && videoFile) { uplodingVideo(); }
  }, [videoFile]);
  useEffect(() => {
    if (videoVisibilityData && currentStep === 3) {
      if (!subscriptionDetails?.has_activePlan) {
        handleClose();
        navigate(Pathname.purchasePlan('video', videoID));
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'You must have purchase a plan to upload a video' });
      } else {
        updateVisibilityContent();
      }
    }
  }, [videoVisibilityData]);

  useEffect(() => (() => {
    videoThumbnails.forEach((videoThumbnail) => { URL.revokeObjectURL(videoThumbnail); });
  }), []);

  useEffect(() => {
    if (videoVisibility === videoVisibilityOptions[2].value) {
      document.getElementById('group-selector').scrollIntoView();
    }
  }, [videoVisibility]);

  const VideoFile = (e) => {
    // const file = e.target.files[0];
    const file = e;

    const fileReader = new FileReader();

    if (file.type.match('image')) {
      fileReader.onload = () => {
        setDefaultVideoThumbnail(fileReader.result);
      };

      fileReader.readAsDataURL(file);
    } else {
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.getElementById('video-player');

        const snapImage = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          // const success = image.length > 100000;
          const success = true;

          if (success) {
            // setDefaultVideoThumbnail(image);
            URL.revokeObjectURL(url);
          }

          return success;
        };

        const timeupdate = () => {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };

        video.addEventListener('loadeddata', () => {
          if (snapImage()) {
            setVideoDetails({ name: file.name, size: file.size, duration: video.duration });
            video.removeEventListener('timeupdate', timeupdate);
          }
        });

        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        video.muted = true;
        video.playsInline = true;

        if (video.play) { video.play(); }
      };

      fileReader.readAsArrayBuffer(file);
    }
    setCurrentStep(0);
    setVideoFile(file);
    // setCurrentStep(1);
    const videoFileSizeInMb = parseFloat((file.size / 1048576).toFixed(2));
    const totalUsedStorage = parseFloat(subscriptionDetails?.storageUsed?.used_storage) + videoFileSizeInMb;
    if (totalUsedStorage > parseFloat((subscriptionDetails?.storageUsed?.total_storage))) {
      handleClose();
      setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
    }
  };

  const PhotoFile = (e) => {
    const selectedFiles = e.target.files;
    setMultiplePhoto(selectedFiles);
    const photoData = [];
    let size = 0;
    if (selectedFiles) {
      Array.from(selectedFiles).forEach((file) => {
        size += file.size;
        photoData.push({ 'url': URL.createObjectURL(file), 'file': file });
      });
      setAlbumSize(size);
      const photoArray = [...photoFile]; // make a separate copy of the array
      photoArray.push(...photoData);
      setPhotoFile(photoArray);
      setAlbumPhotoCount(photoArray?.length);
      setChooseAlbumStep(2);
    }
    const albumSizeInMb = parseFloat((size / 1048576).toFixed(2));
    const totalUsedStorage = parseFloat(subscriptionDetails?.storageUsed?.used_storage) + albumSizeInMb;
    if (totalUsedStorage > parseFloat((subscriptionDetails?.storageUsed?.total_storage))) {
      handleClose();
      setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
    }
  };

  const removePhoto = (index) => {
    const photoArray = [...photoFile]; // make a separate copy of the array
    photoArray.splice(index, 1);
    setPhotoFile(photoArray);
  };

  const getRandomImages = () => {
    let randomArray = [];
    if (photoFile.length > 3) {
      randomArray = photoFile.sort(() => 0.5 - Math.random()).slice(0, 3);
    } else {
      randomArray = [...photoFile];
    }
    setAlbumThumbnails(randomArray);
    setDefaultAlbumThumbnail(randomArray[0]);
    setChooseAlbumStep(3);
  };
  const VideoThumbnails = async (e) => {
    const files = e?.target?.files;
    // const totalFiles = files.length;
    const tmpVideoThumbnails = [];

    const file = files[0];
    const base64 = await convertBase64(file);
    tmpVideoThumbnails.push(base64);
    setDefaultVideoThumbnail(base64);
    setThumbnailType('custom');
    setCustomVideoThumbnails(tmpVideoThumbnails);
  };
  const generateCustomThumbnail = async (e) => {
    const files = e?.target?.files;
    const tmpAlbumThumbnails = [];

    const file = files[0];
    const base64 = await convertBase64(file);
    tmpAlbumThumbnails.push(base64);
    setDefaultAlbumThumbnail({ 'file': file, 'url': URL.createObjectURL(file) });
    setCustomAlbumThumbnails([{ 'file': file, 'url': URL.createObjectURL(file) }]);
  };

  const selectGroup = (id) => {
    if (videoGroup.includes(id)) {
      setVideoGroup(videoGroup.filter((item) => item !== id));
    } else {
      setVideoGroup([...videoGroup, id]);
    }
  };

  return (
    <>
      <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
        <div className={styles.body}>
          <video id="video-player" style={{ display: 'none' }} />
          <input
            accept="image/*"
            disabled={uploadingVideo}
            id="video-thumbnails"
            multiple
            onChange={(e) => { VideoThumbnails(e); }}
            style={{ display: 'none' }}
            type="file"
          />

          <DialogTitle className={styles.header}>
            <div className={styles.headerContent}>
              <BackIcon className={styles.backButton} onClick={uploadingVideo ? undefined : handleClose} />
              <Text.PageTitle className={styles.headerText}>
                {!videoIsPublished && !albumPublished
                  ? (currentStep || uploadType === 'video')
                    ? (videoTitle || videoDetails.name || uploadText?.videoHeader || 'Upload Videos')
                    : chooseAlbumStep ? (albumTitle || uploadText?.albumHeader || 'Upload Photo') : (uploadText?.header || 'Choose what to upload ')
                  : (videoIsPublished ? (uploadText?.videoPublished || 'Video Published') : ('Album Published'))}
              </Text.PageTitle>
              <CloseIcon className={styles.closeButton} onClick={handleClose} />
              {/* <CloseIcon className={styles.closeButton} onClick={uploadingVideo ? undefined : onHide} /> */}
            </div>
            {!videoIsPublished && (
            <>
              <ProgressTracker currentStep={currentStep} steps={[(uploadText?.detail || 'Details'), (uploadText?.categories || 'Categories'), (uploadText?.previousDetails || 'Previous Details')]} />
            </>
            )}
            {!videoIsPublished && !albumPublished && uploadType === 'photo' && (chooseAlbumStep === 3 || chooseAlbumStep === 4) && (
            <ProgressTracker currentStep={chooseAlbumStep === 3 ? 1 : 2} steps={[(uploadText?.detail || 'Details'), (uploadText?.summary || 'Summary')]} />
            )}
          </DialogTitle>

          {videoIsPublished || runLoader || albumPublished
            ? (
              <LoaderContent
                videoUploadingCompleted={videoUploadingCompleted}
                videoUploadPercentage={videoUploadPercentage}
                currentStep={currentStep}
                contentType={contentType}
                addingPhotos={addingPhotos}
                albumStatus={albumStatus}
                uploadText={uploadText}
              />
            )
            : !currentStep
              ? (
                uploadType === 'video' ? (
                  <VideoSelect fileInputRef={fileInputRef} VideoFile={VideoFile} uploadText={uploadText} onHide={handleClose} />
                ) : uploadType === 'photo' ? (
                  chooseAlbumStep === 1 ? (
                    <PhotoSelect fileInputRef={fileInputRef} PhotoFile={PhotoFile} uploadText={uploadText} />
                  ) : (
                    chooseAlbumStep === 2 && photoFile.length > 0 ? (
                      <PhotoPreview
                        photoFile={photoFile}
                        photoUpload={PhotoFile}
                        removePhoto={removePhoto}
                        fileInputRef={fileInputRef}
                        handleClose={handleClose}
                        setChooseAlbumStep={setChooseAlbumStep}
                        fromContentLayout={isAnExistingAlbum}
                        uploadPhoto={uploadPhotoToExistingAlbum}
                        getRandomImages={getRandomImages}
                        addingPhotos={addingPhotos}
                        uploadText={uploadText}
                        commonText={commonText}
                      />
                    ) : (
                      chooseAlbumStep === 3 ? (
                        <AlbumDetails
                          setChooseAlbumStep={setChooseAlbumStep}
                          setAlbumTitle={setAlbumTitle}
                          albumTitle={albumTitle}
                          albumDescription={albumDescription}
                          setAlbumDescription={setAlbumDescription}
                          customAlbumThumbnails={customAlbumThumbnails}
                          defaultAlbumThumbnail={defaultAlbumThumbnail}
                          setDefaultAlbumThumbnail={setDefaultAlbumThumbnail}
                          generateCustomThumbnail={generateCustomThumbnail}
                          albumThumbnails={albumThumbnails}
                          addOrEditAlbum={addOrEditAlbum}
                          albumSize={albumSize}
                          albumPhotoCount={albumPhotoCount}
                          uploadText={uploadText}
                          commonText={commonText}
                        />
                      ) : chooseAlbumStep === 4 ? (
                        <AlbumSummary
                          videoVisibilityOptions={videoVisibilityOptions}
                          albumVisibility={albumVisibility}
                          setAlbumVisibility={setAlbumVisibility}
                          selectGroup={selectGroup}
                          videoGroup={videoGroup}
                          publishAlbum={publishAlbum}
                          defaultAlbumThumbnail={defaultAlbumThumbnail}
                          albumSize={albumSize}
                          albumPhotoCount={albumPhotoCount}
                          addingPhotos={addingPhotos}
                          uploadText={uploadText}
                          commonText={commonText}
                          visibilityText={visibilityText}
                          setChooseAlbumStep={setChooseAlbumStep}
                          chooseAlbumStep={chooseAlbumStep}
                        />
                      ) : (
                        null
                      )
                    )
                  )
                ) : (
                  <ChooseWhatUpload
                    setUpdateType={setUpdateType}
                    setChooseAlbumStep={setChooseAlbumStep}
                    getAlbums={getAlbumsList}
                    albumList={albumList}
                    gettingAlbums={gettingAlbumList}
                    setExistingAlbum={setExistingAlbum}
                    chooseExistingAlbum={chooseExistingAlbum}
                    updateAnExistingAlbum={updateAnExistingAlbum}
                    uploadText={uploadText}
                    commonText={commonText}
                  />
                )
              )
              : currentStep === 1
                ? (
                  <VideoDetails
                    uploadText={uploadText}
                    videoTitle={videoTitle}
                    setVideoTitle={setVideoTitle}
                    videoDescription={videoDescription}
                    setVideoDescription={setVideoDescription}
                    customVideoThumbnails={customVideoThumbnails}
                    setDefaultVideoThumbnail={setDefaultVideoThumbnail}
                    setThumbnailType={setThumbnailType}
                    videoThumbnails={videoThumbnails}
                    thumbnailGenerationStatus={thumbnailGenerationStatus}
                    videoFile={videoFile}
                    uploadingVideo={uploadingVideo}
                    defaultVideoThumbnail={defaultVideoThumbnail}
                    videoDetails={videoDetails}
                    videoIsUploaded={videoIsUploaded}
                    uploadedData={uploadedData}
                    moveToNextStep={moveToNextStep}
                    uploadVideo={uploadVideo}
                    commonText={commonText}
                    conversionStart={conversionStart}
                  />
                )
                : currentStep === 2
                  ? (
                    <>

                      <UpdateCategoryPlaylist
                        conversionStatus={conversionStatus}
                        uploadedData={uploadedData}
                        setUploadedData={(data) => setUploadedData(data)}
                        moveToNextStep={() => moveToNextStep()}
                        moveToPreviousStep={() => moveToPreviousStep()}
                        currentStep={currentStep}
                        videoID={videoID}
                        conversionStart={conversionStart}
                        uploadText={uploadText}
                        commonText={commonText}
                      />
                    </>
                  )
                  : currentStep === 3
                    ? (
                      <VideoSummary
                        setVideoVisibility={setVideoVisibility}
                        videoVisibility={videoVisibility}
                        defaultVideoThumbnail={defaultVideoThumbnail}
                        videoDetails={videoDetails}
                        moveToPreviousStep={moveToPreviousStep}
                        updateVisibility={updateVisibility}
                        videoVisibilityOptions={videoVisibilityOptions}
                        videoGroup={videoGroup}
                        selectGroup={selectGroup}
                        conversionStart={conversionStart}
                      />
                    )
                    : null}
        </div>
      </Fade>
    </>

  );
};
export default Modal;
