import React from 'react';
import cn from 'classnames';
import pt from 'prop-types';
import styles from '../index.module.css';

const PersonButton = ({ data, isDisabled, isSelected, onClick }) => {
  const { email, firstname, lastname, image: dpURL } = data;
  const name = firstname ? `${firstname.trim()} ${lastname.trim()}` : null;
  const firstLetter = name ? name?.charAt(0).toUpperCase() : email ? email?.charAt(0).toUpperCase() : '';
  const color = ['#0D579B', '#33691E', '#455B64', '#68A038', '#738B96', '#5E4038', '#5967B9', '#69B5E2'];
  const randomColor = color[Math.floor(Math.random() * color.length)];
  return (
    <>
      <button
        className={cn({ [styles.personButton]: true, [styles.selected]: isSelected })}
        disabled={isDisabled}
        onClick={onClick}
      >
        <div className={styles.personButtonDP}>
          {dpURL
            ? <img alt={`person - ${name}`} className={styles.personDPImage} src={dpURL} />
            : (
              <div
                className={styles.personDPText}
                style={{
                  background: randomColor
                }}
              >
                {firstLetter}
              </div>
            )}
        </div>
        <div className={styles.personButtonTextContent}>
          <div className={styles.personButtonName}>{name}</div>
          <div className={styles.personButtonEmail}>{email}</div>
        </div>
      </button>
    </>
  );
};

PersonButton.defaultProps = {
  isDisabled: false,
  isSelected: false,
  onClick: undefined,
};

PersonButton.propTypes = {
  data: pt.object.isRequired,
  isDisabled: pt.bool,
  isSelected: pt.bool,
  onClick: pt.func,
};
export default PersonButton;
