import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Drawer,
  withStyles,
} from '@material-ui/core';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import { ReactComponent as ChevronUp } from 'Assets/chevron-up.svg';
import { Logo } from '../logo';
import styles from './index.module.css';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    marginBottom: -1,
    height: 48,
    minHeight: 48,
    padding: '-',
    '&$expanded': {
      minHeight: 48,
      padding: '-',
    },
  },
  content: {
    margin: '0',
    alignItems: 'center',
    padding: '-',
  },
  expanded: {
    padding: '-',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({ root: { padding: 0 } }))(MuiAccordionDetails);

const LinkAccordion = ({ name, links, icon: Icon }) => {
  const [isExpanded, setExpansion] = useState(false);
  const toggleExpansion = () => { setExpansion(!isExpanded); };

  return (
    <Accordion square expanded={isExpanded} onChange={toggleExpansion}>
      <AccordionSummary className={styles.slidingSidebarLink}>
        {/* <Icon className={styles.slidingSidebarLinkIcon} /> */}
        <span className={styles.slidingSidebarLinkName}>{name}</span>
        <ChevronUp className={styles.chevronUp} style={{ ...!isExpanded && { transform: 'rotateZ(180deg)' } }} />
      </AccordionSummary>
      <AccordionDetails className={styles.slidingSidebarLinksContainer}>
        {links.map(({ name: linkName, link }, idx) => (
          <NavLink
            activeClassName={styles.currentSlidingSidebarLink}
            className={cn({ [styles.slidingSidebarLink]: true, [styles.slidingSidebarChildLink]: true })}
            key={idx}
            to={link}
          >
            <span className={styles.slidingSidebarLinkName}>{linkName}</span>
          </NavLink>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const Sidebar = ({ onClose, noBottomBar, noFixedSidebar, sidebarIsVisible }) => {
  const { langText } = useContext(AppContext);
  const { isInActiveUser } = useContext(AuthContext);

  const sideBarText = langText?.sidebar;
  const Links = [
    { name: 'Photo', sidebarName: 'Photo', link: Pathname.photo },
    { name: 'Video', sidebarName: 'Video', link: Pathname.video },
    { name: 'Documents', sidebarName: 'Documents', link: Pathname.document },
    { name: 'Google Drive', sidebarName: 'Google Drive', link: Pathname.gdfiles },
    { name: 'Dropbox', sidebarName: 'Dropbox', link: Pathname.dropdownfiles },
  ];
  return (
    <>
      <div className={cn({ [styles.sidebarFill]: true, [styles.noBottomBar]: noBottomBar })}>
        <div className={styles.sidebarContent}>&nbsp;</div>
      </div>
      <aside
        className={cn({
          [styles.sidebar]: true,
          [styles.noBottomBar]: noBottomBar,
          [styles.noFixedSidebar]: noFixedSidebar,
        })}
      >
        <div className={styles.sidebarContent}>
          {Links.map(({ icon: Icon, link, name }, idx) => (
            <NavLink key={idx} to={link} activeClassName={styles.currentLink} className={styles.link}>
              {/* <Icon className={styles.linkIcon} /> */}
              <span className={styles.linkName}>{name}</span>
            </NavLink>
          ))}
        </div>
      </aside>
      <Drawer anchor="left" open={sidebarIsVisible} onClose={onClose}>
        <div className={styles.slidingSidebarContainer}>
          <div className={styles.slidingSidebar}>
            <div className={styles.logoContainer}>
              <Logo />
            </div>
            <div className={styles.slidingSidebarLinksContainer}>
              {Links.map(({ childLinks, link, sidebarName, icon: Icon }, idx) => (!childLinks
                ? (
                  <NavLink
                    activeClassName={styles.currentSlidingSidebarLink}
                    className={styles.slidingSidebarLink}
                    key={idx}
                    to={link}
                  >
                    {/* <Icon className={styles.slidingSidebarLinkIcon} /> */}
                    <span className={styles.slidingSidebarLinkName}>{sidebarName}</span>
                  </NavLink>
                )
                : (
                  <LinkAccordion name={sidebarName} key={idx} links={childLinks} icon={Icon} />
                )
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
