export const Pathname = {
  authentication: {
    signIn: '/sign-in',
  },
  photo: '/photo',
  document: '/document',
  video: '/video',
  dropdownfiles: '/dropdownfiles',
  gdfiles: '/gdfiles'
};
