import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { validate as isValidEmail } from 'email-validator';
import { Pathname } from 'Routes';
import { Button } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { dataFetcher, endpoints, dataFetcherSign } from 'Api';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import styles from './index.module.css';

const transitionTimeout = 300;

export const SignIn = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, langText, userDetails, setUserDetails, getMySocialAccounts } = useContext(AppContext);
  const { setToken, getToken, setUserSignInStatus } = useContext(AuthContext);
  const authenticationText = langText?.authentication;
  const commonText = langText?.common;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailValidity] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [signInFormData, setSignInFormData] = useState(undefined);

  const { isValidating: requestingCode, mutate: requestVerificationCode } = useSWR([endpoints.sendOtpToMobile], {
    fetcher: (url) => dataFetcher(url, { token: getToken(), type: 'mobile' }),
    onSuccess: ({ success, message }) => {
      if (success) {
        sessionStorage.setItem('#fs656gfhllh@#$', true);
        navigate(Pathname.authentication.verifyCode);
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signIn, signInFormData], {
    fetcher: (url, formData) => dataFetcherSign(url, formData),
    onSuccess: ({ status, is_verified, data, message }) => {
      if (status) {
        if (is_verified) {
          setToken(data?.access_token);
          setUserDetails({ ...userDetails, email, mobile: phoneNumber });
          window.sessionStorage.setItem('signInStatus', true);
          setUserSignInStatus(true);
          getMySocialAccounts();
          setAppSnackbar({ message: 'Logged in Succesfully!' || authenticationText?.successMsg, isVisible: true });
          navigate(Pathname.photo);
        } else {
          requestVerificationCode();
        }
      } else {
        setAppSnackbar({ message: message || commonText?.errorMessage, isVisible: true, type: 'error' });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      const pswrdMessage = responseData?.messages?.password;
      if (pswrdMessage === undefined) {
        const errMessage = responseData?.messages?.message;
        setAppSnackbar({ message: errMessage || commonText?.errorMessage, isVisible: true, type: 'error' });
      } else {
        setAppSnackbar({ message: pswrdMessage || commonText?.errorMessage, isVisible: true, type: 'error' });
      }
    },
  });

  const updateSignInData = () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('mobile', phoneNumber);
    setSignInFormData(formData);
  };

  useEffect(() => {
    if (signInFormData) {
      signInUser();
    }
  }, [signInFormData]);

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  return (
    <AuthPage>
      <form onSubmit={(e) => { e.preventDefault(); updateSignInData(); }} className={styles.signInForm}>
        <Input
          defaultValue={email}
          label={authenticationText?.description || 'Login or create an account with just your email'}
          onInput={(e) => {
            e.persist();
            emailInputHandler(e);
          }}
          placeholder={authenticationText?.email || 'Email'}
          type="email"
          autoFocus

        />
        <Input
          onInput={({ target: { value: inputPassword } }) => { setPassword(inputPassword); }}
          placeholder={authenticationText?.password || 'Password'}
          type="password"
          noLabel
          autoFocus={false}

        />
        {/* <PhoneInput
          placeholder={authenticationText?.enterYourPhoneNumber || 'Enter your phone number'}
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="US"
        /> */}
        <br />
        <Button blockText isBlue isDisabled={!email || !emailIsValid || !password} isFullWidth isLarge submit>
          {authenticationText?.login || 'Login'}
        </Button>
      </form>
    </AuthPage>
  );
};
