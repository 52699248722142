/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-script-url */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as MenuIcon } from 'Assets/menu.svg';
import { ReactComponent as VideoUploadIcon } from 'Assets/video-upload.svg';
import { ReactComponent as PersonIcon } from 'Assets/person-profile.svg';
import DropboxIcon from 'Assets/images/drop-box.png';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import AlterImage from 'Assets/alter-image.png';
import ArrowBackIos from 'Assets/backArrowIcon.svg';
import { Logo } from '../logo';
import { Button } from '../button';
import ProgressBox from '../progressBox';
import styles from './index.module.css';

const DEVELOPER_KEY = 'AIzaSyAxLpv2Ysl_GzV0JUEIqn0Li1QDZckRwEs';
const CLIENT_ID = '301348770380-ju0hsf7ta6f5e0t7thkscjpfonoc9rc7.apps.googleusercontent.com';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive';

export const Header = ({
  glassCompactHeaderBG,
  isProfileHeader,
  hideMenu,
  noHeader,
  noRegularHeader,
  onMenuClick,
  transparentCompactHeader,
  userName,
  userProfileLink,
  headerTheme,
  videoBanner,
  layoutData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAppSnackbar, googleCode, setGoogleCode, showProgressBox, fileHandler, dropboxSocialId, googleSocialId, getMySocialAccounts, googleProfilePic, googleLogout, dropboxLogout } = useContext(AppContext);
  const [pageHasScrolled, setPageScroll] = useState(false);
  const { removeToken, setUserSignInStatus } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const scrollHandler = () => {
      if ((document.body.scrollTop > 0) || (document.documentElement.scrollTop > 0)) {
        setPageScroll(true);
      } else {
        setPageScroll(false);
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => { window.removeEventListener('scroll', scrollHandler); };
  }, []);

  const { isValidating: verifyingGoogle, mutate: verifyGoogleUser } = useSWR([
    endpoints.verifyGoogleUser, googleCode], {
    fetcher: (url, googleCode) => dataFetcher(url, { code: googleCode }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        if (message === 'Access Token Verified Successfully') {
          getMySocialAccounts();
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.messages?.error;
      setAppSnackbar({ isVisible: true, type: 'error', message: errorMessage || 'Oops! Something went wrong!!' });
    },
  });

  const revealProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        CLIENT_ID,
        SCOPES,
      });
    };
    gapi.load('client:auth2', initClient);
  }, [googleCode]);

  const onSuccess = (res) => {
    setGoogleCode(res?.code);
    verifyGoogleUser();
  };
  const loading = () => {
    // console.log('loading');
  };
  const onFailure = (err) => {
    setGoogleCode(null);
  };

  const logOutHandler = () => {
    removeToken();
    sessionStorage.removeItem('signInStatus');
    setUserSignInStatus(false);
  };

  return (
    <>
      <header
        className={cn({
          [styles.glassCompactHeaderBG]: glassCompactHeaderBG,
          [styles.header]: true,
          [styles.noHeader]: noHeader,
          [styles.noRegularHeader]: noRegularHeader || isProfileHeader,
          [styles.pageScrolled]: pageHasScrolled,
          [styles.transparentCompactView]: transparentCompactHeader,
          [styles.headerTheme]: !location.pathname.includes('/profile')
            && !location.pathname.includes('/plan')
            && !location.pathname.includes('/sign-in')
            && !location.pathname.includes('/getting-started')
        })}
      >
        {
          (location.pathname.includes('/access') || location.pathname === '/' || location.pathname === '') ? (
            <div className={styles.semiOvalWrap}>
              <div className={styles.box} />
            </div>
          ) : null
        }
        <div className={styles.headerContent}>
          <Link className={styles.regularView} to={Pathname.photo}>
            <Logo isLarge />
          </Link>
          {hideMenu
            ? <>&nbsp;</>
            : (
              <button className={cn({ [styles.menuButton]: true, [styles.compactView]: true })}>
                <MenuIcon className={styles.menuIcon} />
              </button>
            )}
          {dropboxSocialId ? (
            <button
              onClick={dropboxLogout}
              className={cn({ [styles.uploadButton]: true, [styles.dropboxClass]: true, [styles.regularView]: true, [styles.uploadButtonHover]: true })}
            >
              <img src={DropboxIcon} alt="drop-box" className={styles.dropboxIcon} />
              <span className={styles.uploadText}>Logout</span>
            </button>

          ) : (
            <button onClick={window.doAuth} className={cn({ [styles.uploadButton]: true, [styles.dropboxClass]: true, [styles.regularView]: true, [styles.uploadButtonHover]: true })}>
              <img src={DropboxIcon} alt="drop-box" className={styles.dropboxIcon} />
              <span className={styles.uploadText}>Sign In</span>
            </button>

          )}

          {googleSocialId ? (
            <div className={styles.googleClass}>
              <GoogleLogout clientId={CLIENT_ID} buttonText="Logout" className={styles.buttonGoogle} onLogoutSuccess={googleLogout} />
            </div>

          ) : (
            <div className={styles.googleClass}>
              <GoogleLogin
                theme="dark"
                clientId={CLIENT_ID}
                scope={SCOPES}
                onSuccess={onSuccess}
                onFailure={onFailure}
                onRequest={loading}
                responseType="code"
                prompt="consent"
                className={styles.buttonGoogle}
                buttonText="Sign In"
              />
            </div>

          )}
          <div className={styles.options}>
            <button
              className={cn({ [styles.uploadButton]: true, [styles.regularView]: true, [styles.uploadButtonHover]: true })}
            >
              <span className={styles.uploadText}>Upload</span>
              <div className={styles.uploadIcon}>+</div>
            </button>
            <input
              type="file"
              id="file-multiple"
              accept=".mp4,.mov,.m4v,.png,.jpg,.jpeg,.txt,.pdf,.doc,.docx"
              className={styles.fileUploadButton}
              onInput={(e) => {
                fileHandler(e);
              }}
              multiple
            />
            <button
              className={cn({ [styles.uploadButton]: true, [styles.compactView]: true })}
            >
              <VideoUploadIcon className={styles.uploadIcon} />
            </button>
            <button
              aria-controls="profile-menu"
              className={cn({ [styles.profileButton]: true, [styles.regularView]: true })}
              onClick={revealProfileMenu}
            >
              {googleProfilePic ? (
                <img src={googleProfilePic} className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border2}`} />
              ) : (
                <PersonIcon className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border2}`} />
              )}
            </button>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logOutHandler}>Logout</MenuItem>
          </Menu>
        </div>
      </header>
      <div
        className={cn({
          [styles.headerFill]: true,
          [styles.noHeader]: noHeader || videoBanner,
          [styles.noRegularHeader]: noRegularHeader || isProfileHeader,
        })}
      />
      <header className={cn({ [styles.profileHeader]: true, [styles.hidden]: !isProfileHeader || videoBanner })}>

        <div className={styles.profileHeaderContent}>

          <div className={styles.leftContent} style={{ flex: '1' }}>
            {
              !location.pathname.includes('/profile')
                ? (
                  <div
                    className={`${!location.pathname.includes('/video/') && styles.backButton}`}
                  >

                    <img
                      src={ArrowBackIos || AlterImage}
                      style={{ color: 'white', cursor: 'pointer', marginRight: '0' }}
                      fontSize="large"
                      onClick={() => navigate(-1)}
                      className={styles.profileHeaderIconButtonIcon}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ) : null
            }
          </div>
        </div>
      </header>
      {
        showProgressBox ? <ProgressBox /> : null
      }
    </>
  );
};
