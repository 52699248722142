import Lottie from 'react-lottie';
import cn from 'classnames';
import VideoUploading from 'Assets/video-uploading.json';
import {
  DialogContent,
  LinearProgress,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { ReactComponent as AddIcon } from 'Assets/add.svg';
import AlterImage from 'Assets/alter-image.png';
import { Button } from '../../button';
import { ContentBox, ContentBoxSection } from './ContentBox';
import VideoPreview from './VideoPreview';
import { DialogFooter } from './Material-reDesign';
import InputField from './InputField';
import styles from '../index.module.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const VideoDetails = ({ uploadText, videoTitle, setVideoTitle, videoDescription, setVideoDescription, customVideoThumbnails, setThumbnailType, setDefaultVideoThumbnail, videoThumbnails,
  thumbnailGenerationStatus, videoFile, uploadingVideo, defaultVideoThumbnail, videoDetails, videoIsUploaded, uploadedData, moveToNextStep, commonText, uploadVideo, editFlow, conversionStart }) => (
    <>
      <DialogContent className={styles.content}>
        {uploadingVideo && (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: VideoUploading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                },
              }}
              height={16}
              style={{ flexShrink: 0 }}
              width="100%"
            />
            <br />
          </>
        )}
        <ContentBox flex>
          <div className={styles.contentBox}>
            <ContentBoxSection title={uploadText?.detail || 'Details'} className={styles.detailsBoxSection}>
              <InputField
                defaultValue={videoTitle || ''}
                label={uploadText?.title || 'Title'}
                isDisabled={uploadingVideo}
                onChange={setVideoTitle}
                placeholder={uploadText?.titleVideoPlaceholder || 'Add a title to your video'}
                type="text"
              />
              <InputField
                defaultValue={videoDescription || ''}
                label={uploadText?.description || 'Description'}
                isDisabled={uploadingVideo}
                onChange={setVideoDescription}
                placeholder={uploadText?.descriptionVideoPlaceholder || 'Short lens view of your video, tell your viewers what your video is about'}
                type="textarea"
              />
            </ContentBoxSection>
            <ContentBoxSection
              title={uploadText?.thumbnail || 'Thumbnail'}
              description={uploadText?.thumbnailVideoDescription || 'Select or upload a picture that shows what’s in your video. A great thumbnail stands out and draws viewers’ attention.'}
              className={styles.thumbnailBoxSection}
            >
              <div className={styles.videoThumbnailsContainer}>
                <label
                  className={cn({ [styles.videoThumbnail]: true, [styles.thumbnailUploadButton]: true })}
                  htmlFor="video-thumbnails"
                >
                  <AddIcon className={styles.videoThumbnailAddIcon} />
                </label>
                {
                  thumbnailGenerationStatus ? (
                    <label
                      className={cn({ [styles.videoThumbnail]: true, [styles.thumbnailUploadButton]: true })}
                      htmlFor="video-thumbnails"
                    >
                      <CircularProgress className={styles.videoThumbnailAddIcon} />
                    </label>
                  ) : null
                }
                {customVideoThumbnails?.map((videoThumbnail, idx) => (
                  <div
                    aria-hidden="true"
                    className={styles.videoThumbnail}
                    key={idx}
                    onClick={() => {
                      setDefaultVideoThumbnail(videoThumbnail);
                      setThumbnailType('custom');
                    }}
                    style={{
                      cursor: 'pointer',
                      border: `${defaultVideoThumbnail === videoThumbnail ? '4px solid #5aa5ef'
                        : ''}`
                    }}
                  >
                    <img
                      alt={`video thumbnail ${idx}`}
                      className={styles.videoThumbnailImage}
                      src={videoThumbnail || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ))}
                {videoThumbnails?.map((videoThumbnail, idx) => (
                  <div
                    aria-hidden="true"
                    className={styles.videoThumbnail}
                    key={idx}
                    onClick={() => { setDefaultVideoThumbnail(videoThumbnail); setThumbnailType('normal'); }}
                    style={{
                      cursor: 'pointer',
                      border: `${defaultVideoThumbnail === videoThumbnail ? '4px solid #5aa5ef'
                        : ''}`
                    }}
                  >
                    <img
                      alt={`video thumbnail ${idx}`}
                      className={styles.videoThumbnailImage}
                      src={videoThumbnail || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ))}

              </div>
            </ContentBoxSection>
          </div>
          <VideoPreview
            defaultVideoThumbnail={defaultVideoThumbnail}
            details={videoDetails}
            type="details"
            editFlow={editFlow}
          />
        </ContentBox>
      </DialogContent>
      <DialogFooter twoEnd={conversionStart}>
        <div className={styles.buttonOnTwoEnd}>
          {
            conversionStart ? (
              <div className={styles.leftContainer}>
                <div className={styles.iconContainer}>
                  <CloudUploadOutlined
                    className={styles.videoUploadIcon}
                    fontSize="large"
                  />
                </div>
                <div className={cn({ [styles.progressbar]: true })}>
                  <BorderLinearProgress />
                  <div className={styles.processingText}>
                    Processing in background...
                  </div>
                </div>
              </div>
            ) : null
          }
          <Button
            className={styles.navButton}
            isBlue
            isDisabled={uploadingVideo || !(videoTitle && videoDescription && (videoThumbnails?.length || customVideoThumbnails?.length) && videoFile)}
            isOutlined
            onClick={(videoIsUploaded
              && (videoDescription === uploadedData.description)
              && (videoTitle === uploadedData.title)
              && (videoThumbnails === uploadedData.thumbnails)
            )
              ? moveToNextStep
              : uploadVideo}
          >
            {videoIsUploaded
              ? uploadingVideo ? (commonText?.updating || 'Updating ...') : (commonText?.next || 'Next')
              : uploadingVideo ? 'Uploading ...' : (commonText?.next || 'Next')}
          </Button>
        </div>
      </DialogFooter>
    </>
);
export default VideoDetails;
