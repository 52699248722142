import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import {
  SignIn,
  Photo,
  Document,
  Video,
  DropdownFiles,
  GdFiles
} from 'Pages';

export const RouteSwitch = ({ userIsSignedIn }) => {
  const currentLocation = useLocation();
  useEffect(() => { window.scrollTo(0, 0); }, [currentLocation.pathname]);
  return (
    <Routes>
      {
        userIsSignedIn ? (
          <>
            <Route path={Pathname.photo} element={<Photo />} />
            <Route path={Pathname.document}>
              <Document />
            </Route>
            <Route path={Pathname.video} element={<Video />} />
            <Route path={Pathname.gdfiles} element={<GdFiles />} />
            <Route path={Pathname.dropdownfiles} element={<DropdownFiles />} />

            <Route path="*" element={<Navigate to={Pathname.photo} />} />
          </>
        ) : (
          <>
            <Route path={Pathname.authentication.signIn} element={<SignIn />} />
            <Route path="*" element={<Navigate to={Pathname.authentication.signIn} />} />
          </>
        )
      }
    </Routes>
  );
};
