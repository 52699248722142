import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.css';

export const Button = ({
  alignCenter,
  blockText,
  children,
  className,
  glow,
  isBlack,
  isBlue,
  isDarkBlue,
  isDisabled,
  isFullWidth,
  isGray,
  isLarge,
  isOutlined,
  isWhite,
  link,
  submit,
  id,
  ...props
}) => {
  const Element = link ? Link : submit ? 'input' : 'button';
  const elementProps = {
    ...props,
    disabled: isDisabled,
    ...link && { to: link },
    ...!submit && { children },
    ...submit && { type: 'submit', value: children },
    id
  };

  return (
    <Element
      className={cn({
        [styles.blockText]: blockText,
        [styles.button]: true,
        [styles.alignCenter]: alignCenter,
        [styles.glow]: glow,
        [styles.outlined]: isOutlined,
        [styles.large]: isLarge,
        [styles.blackColor]: isBlack,
        [styles.blueColor]: isBlue,
        [styles.darkblueColor]: isDarkBlue,
        [styles.grayColor]: isGray,
        [styles.whiteColor]: isWhite,
        [styles.fullWidth]: isFullWidth,
        [className]: className,
      })}
      {...elementProps}
    />
  );
};
