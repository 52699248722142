import React, { useState } from 'react';
import cn from 'classnames';
import { Header } from '../header';
import { Sidebar } from '../sidebar';
import styles from './index.module.css';

export const Page = ({
  children,
  className,
  containerClassName,
  contentClassName,
  glassCompactHeaderBG,
  id,
  isProfileHeader,
  noBottomBar,
  noDefaultBg,
  noFixedSidebar,
  noHeader,
  noRegularHeader,
  transparentCompactHeader,
  userName,
  userProfileLink,
  headerTheme,
  accessPageBackGround,
  videoBanner,
  layoutData
}) => {
  const [sidebarIsVisible, setSidebarVisibility] = useState(false);

  const revealSidebar = () => { setSidebarVisibility(true); };
  const hideSidebar = () => { setSidebarVisibility(false); };

  return (
    <div className={cn({ [containerClassName]: containerClassName })}>
      <Header
        glassCompactHeaderBG={glassCompactHeaderBG}
        isProfileHeader={isProfileHeader}
        noHeader={noHeader}
        noRegularHeader={noRegularHeader}
        onMenuClick={revealSidebar}
        transparentCompactHeader={transparentCompactHeader}
        userName={userName}
        userProfileLink={userProfileLink}
        headerTheme={headerTheme}
        videoBanner={videoBanner}
        layoutData={layoutData}
      />
      <main className={cn({ [styles.page]: true, [styles.pageBottom]: !accessPageBackGround, [styles.noDefaultBg]: noDefaultBg, [styles.accessPageBackGround]: accessPageBackGround, [className]: className })}>
        <Sidebar
          noBottomBar={noBottomBar}
          noFixedSidebar={noFixedSidebar}
          onClose={hideSidebar}
          sidebarIsVisible={sidebarIsVisible}
        />
        <div className={cn({ [styles.pageContent]: true, [styles.pageContentBottom]: !accessPageBackGround, [contentClassName]: contentClassName })} id={id}>
          {children}
        </div>
      </main>
    </div>
  );
};
