import AXIOS from 'axios';
import endpoints from './endpoints.json';
import baseUrl from './baseURL.json';

const getToken = () => localStorage.getItem('token');
const AxiosCancelToken = AXIOS.CancelToken;

const axios = AXIOS.create({ baseURL: baseUrl.URL, method: 'POST' });

axios.interceptors.request.use((req) => {
  if (req?.data instanceof FormData) {
    const formData = req?.data;

    // if (formData.has('token')) { formData.delete('token'); }

    // formData.append('token', getToken());

    return ({ ...req, data: formData });
  }

  return ({ ...req, data: { ...req.data } });
});

const dataFetcher = (url, data, cancelToken) => axios({
  url,
  ...data && { data },
  ...cancelToken && { cancelToken },
  headers: { Authorization: `Bearer ${getToken()}` },
}).then((res) => res.data);

const dataFetcherSign = (url, data, cancelToken) => axios({
  url,
  ...data && { data },
  ...cancelToken && { cancelToken },
}).then((res) => res.data);

export {
  axios,
  AxiosCancelToken,
  dataFetcher,
  endpoints,
  getToken,
  baseUrl,
  dataFetcherSign
};
