import React from 'react';
import cn from 'classnames';
import logo from 'Assets/Login Logo.png';
import styles from './index.module.css';

export const Logo = ({ className, isLight, isLarge, footerLogo }) => (
  <div className={cn({ [styles.logo]: true, [styles.medium]: isLarge, [className]: className, [styles.footerLogo]: footerLogo })}>
    <img className={styles.logoImage} src={logo} alt="projector logo" />
  </div>

);
