import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useHistory } from 'react-router-dom';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { AppContext, AuthContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints, AxiosCancelToken, baseUrl } from 'Api';
import { Snackbar } from 'Components';
import { Pathname } from 'Routes';
import { RouteSwitch } from './Routes/RouteSwitch';
import styles from './app.module.css';
import 'Styles/site-global.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

const defaultAppSnackbar = { isVisible: false, type: undefined, message: undefined };
const appSnackbarAutoHideDuration = 1500;
const getToken = () => localStorage.getItem('token');
const setToken = (token) => { localStorage.setItem('token', token); };
const removeToken = () => { localStorage.removeItem('token'); };

export const App = () => {
  const [appSnackbar, setAppSnackbar] = useState(defaultAppSnackbar);
  const [showProgressBox, setProgressBox] = useState(false);
  const [files, setFiles] = useState(undefined);
  const [langText, setLangText] = useState(undefined);
  const [userIsSignedIn, setUserSignInStatus] = useState((window.sessionStorage.getItem('signInStatus')) || false);
  const [fileUploadData, setFileUploadData] = useState(undefined);
  const [isUploadSuccess, setUploadStatus] = useState(false);
  const [googleSocialId, setGoogleSocialId] = useState((localStorage.getItem('googleSocialId')) || null);
  const [googleProfilePic, setGoogleProfilePic] = useState(false);
  const [googleCode, setGoogleCode] = useState(null);
  const [dropboxCode, setDropboxCode] = useState(null);
  const [dropboxSocialId, setDropboxSocialId] = useState((localStorage.getItem('dropboxSocialId')) || null);
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    isNewUser: undefined,
  });
  const { isValidating: uploadingFiles, mutate: uploadFiles } = useSWR([
    endpoints.uploadFile, fileUploadData], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ status, message }) => {
      if (status) {
        setUploadStatus(true);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
        setUploadStatus(false);
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setUploadStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
    },
  });
  const { isValidating: gettingSocialAcc, mutate: getMySocialAccounts } = useSWR([
    endpoints.getMySocialAcc], {
    fetcher: (url) => dataFetcher(url, { limit: 10, offset: 0, type: 'all' }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        const filteredGoogle = data.filter((data1) => data1.provider === 'google');
        if (filteredGoogle?.length > 0) {
          localStorage.setItem('googleSocialId', Number(filteredGoogle[0]?.id));
          setGoogleSocialId(filteredGoogle[0]?.id);
          setGoogleProfilePic(filteredGoogle[0]?.picture);
        } else {
          setGoogleSocialId(null);
        }
        const filteredropbox = data.filter((data1) => data1.provider === 'dropbox');
        if (filteredropbox?.length > 0) {
          localStorage.setItem('dropboxSocialId', Number(filteredropbox[0]?.id));
          setDropboxSocialId(filteredropbox[0]?.id);
          // setGoogleProfilePic(filteredropbox[0]?.picture);
        } else {
          setDropboxSocialId(null);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.messages?.error;
      setAppSnackbar({ isVisible: true, type: 'error', message: errorMessage || 'Oops! Something went wrong!!' });
    },
  });
  const { isValidating: loggingOut, mutate: googleLogout } = useSWR([
    endpoints.googleLogout, googleSocialId], {
    fetcher: (url, googleSocialId) => dataFetcher(url, { social_id: googleSocialId }),
    onSuccess: ({ status, message }) => {
      localStorage.removeItem('googleSocialId');
      if (status) {
        setGoogleSocialId(null);
      } else {
        setGoogleSocialId(null);
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: (error) => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
    },
  });
  const { isValidating: verifyingDropboxUser, mutate: verifyDropboxUser } = useSWR([endpoints.verifyDropboxUser, dropboxCode], {
    fetcher: (url, dropboxCode) => dataFetcher(url, { code: dropboxCode }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        localStorage.setItem('dropboxSocialId', Number(data.id));
        setDropboxSocialId(data.id);
      } else {
        setAppSnackbar({ message, isVisible: true, type: 'error' });
      }
    },
    onError: (error) => {
      const responseMsg = error?.response?.data?.message;
      setAppSnackbar({ message: responseMsg, isVisible: true, type: 'error' });
    },
  });
  const { isValidating: logginOut, mutate: dropboxLogout } = useSWR([
    endpoints.dropboxLogout, dropboxSocialId], {
    fetcher: (url, dropboxSocialId) => dataFetcher(url, { social_id: dropboxSocialId }),
    onSuccess: ({ status, message }) => {
      if (status) {
        setDropboxSocialId(null);
        localStorage.removeItem('dropboxSocialId');
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: (error) => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
    },
  });

  const saveFormData = () => {
    const formData = new FormData();
    Object.keys(files).forEach((file) => {
      formData.append('files[]', files[file]);
    });
    setFileUploadData(formData);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('code')) {
      const code = queryParams.get('code');
      // if (dropboxCode !== code) {
      setDropboxCode(code);
      verifyDropboxUser(code);
      queryParams.delete('code');
      // }
    }
  }, [dropboxCode]);

  useEffect(() => {
    if (files) {
      saveFormData();
    }
  }, [files]);

  useEffect(() => {
    if (fileUploadData) {
      uploadFiles();
    }
  }, [fileUploadData]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setUserSignInStatus(true);
    } else {
      setUserSignInStatus(false);
    }
  }, [userIsSignedIn]);

  const fileHandler = (e) => {
    const selectedFiles = e?.target?.files;
    if (selectedFiles?.length) {
      setProgressBox(true);
      setFiles(selectedFiles);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        getToken,
        removeToken,
        setToken,
        setUserSignInStatus,
      }}
    >
      <AppContext.Provider
        value={{
          appSnackbar,
          setAppSnackbar,
          showProgressBox,
          setProgressBox,
          setFiles,
          files,
          langText,
          setFileUploadData,
          fileUploadData,
          uploadFiles,
          uploadingFiles,
          fileHandler,
          isUploadSuccess,
          setUserDetails,
          userDetails,
          googleSocialId,
          setGoogleSocialId,
          googleCode,
          setGoogleCode,
          getMySocialAccounts,
          googleProfilePic,
          googleLogout,
          dropboxSocialId,
          dropboxLogout
        }}
      >
        <div className={styles.app}>
          <Router>
            <RouteSwitch
              {...{
                setUserSignInStatus,
                userIsSignedIn
              }}
            />
          </Router>
        </div>

        <Snackbar
          autoHideDuration={appSnackbarAutoHideDuration}
          isVisible={appSnackbar?.isVisible}
          message={appSnackbar?.message || ''}
          onClose={() => { setAppSnackbar(defaultAppSnackbar); }}
          type={appSnackbar?.type}
        />
      </AppContext.Provider>
    </AuthContext.Provider>
  );
};
