import React, { useContext, useState, useEffect } from 'react';
import { Page, Text, ProjectorShimmer, NoGoogleContent, SubHeading } from 'Components';
import { TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useSWR from 'swr';
import { AppContext } from 'Context';
import AlterImage from 'Assets/No-Preview.png';
import { dataFetcher, endpoints } from 'Api';
import Banner from 'react-js-banner';

import styles from './index.module.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customStyleOnTab: {
    fontSize: '28px',
    color: '#95969A',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  activeTab: {
    fontSize: '28px',
    color: '#02071A',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  banner1Css: { color: '#FFF', backgroundColor: 'green' },
  banner2Css: { color: '#000', backgroundColor: 'grey', fontFamily: 'arial' },
  banner3Css: { color: '#FFF', backgroundColor: 'red', fontSize: 20 }
}));

export const GdFiles = () => {
  const { googleSocialId, googleButton, setAppSnackbar, langText, fileHandler } = useContext(AppContext);
  const [googlePhotos, setgooglePhotos] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const commonText = langText?.common;
  const contentText = langText?.content;
  const [tabTitle, setTabTitle] = useState(parseInt(window.sessionStorage.getItem('tabTitle'), 10) || 0);
  const [type, setType] = useState(1);
  const [notGoogleLogged, setNotGoogleLogged] = useState(0);
  const classes = useStyles();
  const state = {
    banner1Css: { color: '#fff', backgroundColor: '#5aa5ef', fontFamily: 'Poppins', marginTop: '15px' },
  };
  const handleTabChange = (event, newValue) => {
    sessionStorage.removeItem('tabTitle');
    const value = newValue + 1;
    setTabTitle(newValue);
    setNotGoogleLogged(0);
    setType(value);
  };

  const { isValidating: gettingPhotos, mutate: getDriveFiles } = useSWR([endpoints.getDriveFiles, googleSocialId, type, searchKey, setAppSnackbar], {
    fetcher: (url, googleSocialId, type) => dataFetcher(url, { social_id: googleSocialId, offset: 0, limit: 100, search: searchKey, type }),
    onSuccess: ({ status, data, message }) => {
      if (status) {
        setgooglePhotos(data);
      } else {
        setAppSnackbar({ message: message || commonText?.errorMessage, isVisible: true, type: 'error' });
      }
    },
    onError: (error) => {
      const responseMsg = error?.response?.data?.messages?.error;
      setAppSnackbar({ message: responseMsg || commonText?.errorMessage, isVisible: true, type: 'error' });
    },
  });
  useEffect(() => {
    if (googleSocialId) {
      getDriveFiles();
      setNotGoogleLogged(0);
    } else {
      setNotGoogleLogged(1);
    }
  }, [searchKey, type, notGoogleLogged, googleSocialId]);

  const searchHandler = (event) => {
    const lowerCase = event.target.value.toLowerCase();
    setSearchKey(lowerCase);
  };

  return (
    <Page contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <Tabs
        className={styles.tabStyle}
        value={tabTitle}
        TabIndicatorProps={{ style: { background: '#02071A', height: '4px' } }}
        onChange={handleTabChange}
        aria-label="simple tabs example"
      >
        <Tab label={<span className={tabTitle === 0 ? classes.activeTab : classes.customStyleOnTab}>{contentText?.videos || 'Videos'}</span>} />
        <Tab label={<span className={tabTitle === 1 ? classes.activeTab : classes.customStyleOnTab}>{contentText?.photos || 'Photos'}</span>} />
      </Tabs>
      <div className={styles.dashboard}>
        {
            tabTitle === 1 ? <SubHeading contentType="Photos" noOfContents={googlePhotos?.length || 0} /> : <SubHeading contentType="Videos" noOfContents={googlePhotos?.length || 0} />
        }
        { googlePhotos?.length
          ? (
            <div className={styles.searchField}>
              <TextField
                id="outlined-basic"
                onChange={searchHandler}
                variant="outlined"
                label="Search by Name"
                size="small"
              />
            </div>
          )
          : ''}
        {
          gettingPhotos ? (
            <ProjectorShimmer />
          ) : googlePhotos?.length ? (
            <>
              <div className={styles.contentWrapper}>
                {
                  googlePhotos?.map(({ file_url, thumbnail_url, file_name }) => (
                    <div className={styles.imgWrap} key={file_url}>
                      <a href={file_url} target="_blank" rel="noreferrer">
                        <img
                          className={styles.imageStyle}
                          src={thumbnail_url}
                          onError={(e) => {
                            e.target.src = AlterImage;
                          }}
                          // onClick={() => window.open(file_url, '_blank')}
                        />
                      </a>
                      <div className={styles.fileName}>{file_name}</div>
                    </div>
                  ))
                }
              </div>
            </>
          ) : (
            <NoGoogleContent contentType="Content" description="No Content Found" />
          )
        }
        {
          notGoogleLogged
            ? (
              <Banner
                title="Please Login To Your Google Account"
                css={state.banner1Css}
                visibleTime={1500}
              />
            )
            : null
        }
      </div>
    </Page>
  );
};
